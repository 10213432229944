import { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Stack, Typography } from '@mui/material';
import { routes } from 'appConstants';
import {
  authenticationImage1,
  authenticationImage2,
  authenticationImage3,
  authenticationImage4,
  custodyBannerImage,
  custodyFormImage,
  custodyProcessImage,
  custodyRequestImage,
  custodyRequestMobileImage,
  sneakersImage,
} from 'assets/img/CustodyInfo';
import { useShallowSelector } from 'hooks';
import { setActiveModal } from 'store/modals/reducer';
import userSelector from 'store/user/selectors';
import { COLOR_BLACK } from 'theme/variables';
import { Modals, State, UserState } from 'types';

export const CustodyInfo: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { address, id: userId, customUrl } = useShallowSelector<State, UserState>(userSelector.getUser);

  const handleOpenModal = useCallback(
    (type: Modals) => {
      dispatch(
        setActiveModal({
          activeModal: type,
          txHash: '',
          open: true,
        }),
      );
    },
    [dispatch],
  );

  const handleNavigateToCustody = () => {
    if (customUrl || userId) {
      navigate(routes.profile.custody.root.getPath(customUrl || userId));
      return;
    }
    handleOpenModal(Modals.ConnectWallet);
  };

  return (
    <Box>
      <Box
        width="100vw"
        height={{ xs: '500px', sm: '600px', md: '750px', lg: '930px' }}
        position="relative"
        mx={{ xs: -1.25, md: -4, lg: -4, xl: 'calc((1850px - 100vw) / 2 )' }}
        sx={{
          background: `linear-gradient(0deg, rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35)), url(${custodyBannerImage})`,
          backgroundSize: 'cover',
        }}
      >
        <Box position="absolute" right={{ xs: '24px', sm: '50px', lg: '110px' }} top="46%">
          <Typography
            variant="h1"
            color="white"
            mb={2}
            fontSize={{ xs: 52, lg: 96 }}
            className="semiBold"
            noWrap
            lineHeight={{ xs: '60px', lg: '110px' }}
          >
            Safe Custody
          </Typography>
          <Typography variant="subtitle1" color="white" fontSize={{ xs: 16, lg: 28 }} mb={{ xs: 2.5, sm: 5 }}>
            Store, Trade and Flex sneakers like no other.
          </Typography>
        </Box>
      </Box>

      <Stack
        direction={{ xs: 'column-reverse', sm: 'row' }}
        maxWidth="1400px"
        mx="auto"
        mt={{ xs: 3, sm: 5, md: 10, lg: 17 }}
      >
        <Stack
          flex="1 1 0"
          justifyContent="center"
          alignItems={{ xs: 'center', sm: 'start' }}
          spacing={3}
          mr={{ xs: 0, sm: 2, md: 6 }}
        >
          <Typography
            variant="h2"
            fontSize={{ xs: 24, lg: 40 }}
            lineHeight={{ xs: '29px', lg: '60px' }}
            textAlign={{ xs: 'center', sm: 'left' }}
            maxWidth="1050px"
          >
            Fill out the custody request form.
            <br />
            Choose from the seankers list or type in style number of sneakers to request custody for.
          </Typography>
          <Typography
            variant="subtitle1"
            className="purple semiBold"
            fontSize={{ xs: 18, lg: 28 }}
            textAlign={{ xs: 'center', sm: 'start' }}
          >
            *Wallet must be connected to request custody service.
          </Typography>
          <Button
            variant="contained"
            className="unset-case"
            onClick={handleNavigateToCustody}
            sx={{ width: { xs: '100%', lg: '356px' }, maxWidth: '356px' }}
          >
            Request
          </Button>
        </Stack>
        <Stack flex="1 1 0" alignItems="center">
          <Box component="img" src={custodyFormImage} sx={{ width: '100%', maxWidth: '637px' }} />
        </Stack>
      </Stack>

      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        spacing={{ xs: 3, lg: 6 }}
        maxWidth="1400px"
        mx="auto"
        mt={{ xs: 7.5, lg: 20 }}
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography
          variant="h1"
          className="lg"
          width="320px"
          flexShrink={0}
          textAlign={{ xs: 'center', sm: 'start' }}
          sx={{ typography: { xs: 'h2', lg: 'h1' }, lineHeight: { xs: '34px', lg: '60px' } }}
        >
          Once your request is approved, ship your sneakers to meta[z].
        </Typography>
        <Box
          width="100%"
          flexShrink={1}
          sx={{
            overflowX: { xs: 'scroll', sm: 'unset' },
            '&::-webkit-scrollbar': {
              display: 'none',
            },
          }}
        >
          <Box
            component="img"
            src={custodyProcessImage}
            sx={{
              ml: 3,
              width: { xs: 'unset', sm: '90%', md: '100%' },
              maxWidth: '1032px',
              height: { xs: '320px', sm: 'unset' },
            }}
          />
        </Box>
      </Stack>
      <Stack maxWidth="1400px" mx="auto">
        <Typography variant="h2" className="semiBold" fontSize={{ xs: 24, lg: 32 }} mt={{ xs: 5, md: 8.5 }}>
          METAZ Custody center
        </Typography>
        <Typography variant="h2" className="regular" fontSize={{ xs: 16, lg: 24 }}>
          Send your sneakers to the centers below.
          <br />
          Currently, we run custody centers in Korea and USA only.
        </Typography>
        <Stack direction={{ xs: 'column', md: 'row' }} justifyContent="space-between" spacing={2} mt={{ xs: 3, md: 7 }}>
          <Stack>
            <Typography variant="h2" className="semiBold" fontSize={{ xs: 24, lg: 32 }}>
              Korea
            </Typography>
            <Typography className="semiBold" variant="h4" mt={{ xs: 0.25, md: 1 }} fontSize={{ xs: 16, lg: 24 }}>
              Meta Z Custody Center
              <br />
              Zip code: 04778
              <br />
              Address:
              <br />
              서울 성동구 뚝섬로1길 25 서울숲한라에코밸리 B105
              <br />
              B105, Hanla Eco Valley Seoulsoop 25
              <br />
              Tteoksoemro1gil Seongdonggu
              <br />
              Seoul, Republic of Korea
            </Typography>
          </Stack>
          <Stack>
            <Typography variant="h2" className="semiBold" fontSize={{ xs: 24, lg: 32 }}>
              USA
            </Typography>
            <Typography className="semiBold" variant="h4" mt={{ xs: 0.25, md: 1 }} fontSize={{ xs: 16, lg: 24 }}>
              Meta Z Custody Center, USA
              <br />
              Address: <br /> 122 John St Hackensack NJ, 07601
            </Typography>
          </Stack>
        </Stack>
      </Stack>

      <Box
        mt={{ xs: 8, md: 15 }}
        mx={{ xs: -1.25, md: -4, lg: -4, xl: 'calc((1850px - 100vw) / 2 )' }}
        py={{ xs: 5, sm: 14 }}
        width="100vw"
        sx={{ backgroundColor: `${COLOR_BLACK}` }}
      >
        <Stack direction="row" justifyContent="space-between" spacing={7} width="100vw">
          <Box ml={{ xs: 1.25, sm: 4, lg: 'calc((100vw - 1440px) / 2 + 32px)' }}>
            <Box width={{ xs: 'unset', sm: '350px', lg: '600px' }} mr={{ xs: 1.25, sm: 0 }}>
              <Typography
                variant="h1"
                className="white"
                fontSize={{ xs: 24, lg: 48 }}
                lineHeight={{ xs: '30px', lg: '60px' }}
                textAlign={{ xs: 'center', sm: 'start' }}
              >
                Continue and complete the request form by filling out the form with contact and shipment details.
              </Typography>
              <Box
                mt={2}
                display={{ xs: 'block', sm: 'none' }}
                component="img"
                src={custodyRequestMobileImage}
                sx={{ width: '100%', maxWidth: '980px' }}
              />
              <Typography
                className="white"
                mt={5}
                sx={{
                  lineHeight: '30px',
                  fontSize: { xs: '16px', lg: '28px' },
                  br: {
                    display: 'block',
                    content: '""',
                    marginTop: 2,
                  },
                }}
              >
                1. Go to your profile.
                <br />
                2. Click “custody requested” in the tab menu on the Profile page to check the custody request status.
                <br />
                3. Once approved, “Approved” will appear.
                <br />
                4. Click “ Send Shipment Detail” under “Approved” status.
                <br />
                5. Fill in the form with contact and shipment details and submit the form.
              </Typography>
            </Box>
          </Box>
          <Box
            display={{ xs: 'none', sm: 'block' }}
            component="img"
            src={custodyRequestImage}
            sx={{ width: '100%', maxWidth: '980px' }}
          />
        </Stack>
      </Box>

      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        justifyContent="center"
        alignItems="center"
        spacing={{ xs: 5, sm: 10 }}
        py={{ xs: 7.5, sm: 24 }}
        maxWidth="1400px"
        mx="auto"
      >
        <Box flex="1 1 0">
          <Box component="img" src={sneakersImage} sx={{ width: '100%', maxWidth: '582px' }} />
        </Box>
        <Stack maxWidth="600px" flex="1 1 0">
          <Typography
            variant="h1"
            className="lg"
            maxWidth="400px"
            mb={{ xs: 1, sm: 5 }}
            mx={{ xs: 5, sm: 0 }}
            sx={{
              typography: { xs: 'h2', lg: 'h1' },
              lineHeight: { xs: '34px', lg: '60px' },
              textAlign: { xs: 'center', sm: 'start' },
            }}
          >
            Upon the arrival of your sneakers, authentication will take place.
          </Typography>
          <Typography
            mt={5}
            textAlign={{ xs: 'center', sm: 'left' }}
            sx={{
              lineHeight: '30px',
              fontSize: { xs: '18px', lg: '28px' },
              br: {
                display: 'block',
                content: '""',
                marginTop: 2,
              },
            }}
          >
            1. Our experts will inspect the sneakers. <br />
            2. The inspection will be done with various technique and practices.
          </Typography>
        </Stack>
      </Stack>
      <Box
        mx={{ xs: -1.25, md: -4, lg: -4, xl: 'calc((1850px - 100vw) / 2)' }}
        py={{ xs: 5, sm: 14 }}
        width="100vw"
        sx={{ backgroundColor: `${COLOR_BLACK}` }}
      >
        <Box width="100vw" maxWidth="1440px" mx="auto" px={4}>
          <Typography
            variant="h1"
            className="white"
            mb={{ xs: 2.5, sm: 5 }}
            sx={{
              fontSize: { xs: 24, lg: 48 },
              lineHeight: { xs: '30px', lg: '60px' },
              textAlign: { xs: 'center', sm: 'left' },
            }}
          >
            Authentication successful.
          </Typography>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={3}>
            <Stack spacing={3} alignItems="center">
              <Box component="img" src={authenticationImage1} sx={{ width: '100%', maxWidth: '688px' }} />
              <Typography
                lineHeight="30px"
                fontSize={{ xs: '16px', lg: '28px' }}
                className="white"
                maxWidth="520px"
                textAlign="center"
              >
                NFT is minted and dropped into the onwer’s wallet.
              </Typography>
            </Stack>
            <Stack spacing={3} alignItems="center" mx="auto">
              <Box component="img" src={authenticationImage2} sx={{ width: '100%', maxWidth: '688px' }} />
              <Typography
                lineHeight="30px"
                fontSize={{ xs: '16px', lg: '28px' }}
                className="white"
                maxWidth="520px"
                textAlign="center"
              >
                The sneakers are securely stored and the NFT is ready to trade.
              </Typography>
            </Stack>
          </Stack>
          <Typography
            variant="h1"
            className="white"
            mt={{ xs: 5, sm: 10 }}
            mb={{ xs: 2.5, sm: 5 }}
            sx={{
              fontSize: { xs: 24, lg: 48 },
              lineHeight: { xs: '30px', lg: '60px' },
              textAlign: { xs: 'center', sm: 'left' },
            }}
          >
            Authentication failed.
          </Typography>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={3}>
            <Stack alignItems="center" spacing={3}>
              <Box component="img" src={authenticationImage3} sx={{ width: '100%', maxWidth: '688px' }} />
              <Typography
                lineHeight="30px"
                fontSize={{ xs: '16px', lg: '28px' }}
                className="white"
                maxWidth="520px"
                textAlign="center"
              >
                Sneakers are shipped back to the address once the payment for return is settled.
              </Typography>
            </Stack>
            <Stack spacing={3} alignItems="center">
              <Box component="img" src={authenticationImage4} sx={{ width: '100%', maxWidth: '688px' }} />
              <Typography
                lineHeight="30px"
                fontSize={{ xs: '16px', lg: '28px' }}
                className="white"
                maxWidth="520px"
                textAlign="center"
              >
                The status of the custody changes to “Verification failed”
              </Typography>
            </Stack>
          </Stack>
        </Box>
      </Box>

      <Stack
        alignItems="center"
        spacing={{ xs: 4, sm: 10 }}
        py={{ xs: 8, sm: 20 }}
        sx={{ width: { xs: '100%', md: '70%' }, marginX: 'auto' }}
      >
        <Typography
          variant="h1"
          className="lg"
          sx={{
            typography: { xs: 'h2', lg: 'h1' },
            lineHeight: { xs: '38px', lg: '60px' },
            textAlign: 'center !important',
          }}
        >
          Connect your wallet for full meta[z] experience.
        </Typography>
        <Button
          variant="contained"
          disabled={!!address.length}
          onClick={() => handleOpenModal(Modals.ConnectWallet)}
          sx={{ width: { xs: '100%', sm: 520 }, height: { xs: 78, sm: 100 }, textTransform: 'unset', borderRadius: 4 }}
        >
          Connect wallet
        </Button>
      </Stack>
    </Box>
  );
};
